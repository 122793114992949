<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'
import type {
  strapiNavigationItem,
  strapiOptions,
} from '~/interfaces/strapi.interface'
import AkImage from '~/components/Ak/AkImage.vue'
import { useStore } from '~/store/main'

const route = useRoute()
const store = useStore()
const gtm = useGtm()
const { t } = useI18n()

defineProps<{
  top?: strapiNavigationItem[]
  menu?: strapiNavigationItem[]
}>()

const { data: user, status } = useAuth()

const open = ref(false)
const isLaptop = useMediaQuery('(min-width: 1280px)')
const isMobile = useMediaQuery('(max-width: 1279px)')

const options: { data: { attributes: strapiOptions } } | undefined =
  inject('options')

const closeMenu = () => {
  if (store.isMenuAccountOpen) {
    open.value = false
    store.$patch({
      isMenuAccountOpen: false,
    })
  } else {
    open.value = !open.value
  }
}

watch(
  () => route.fullPath,
  () => {
    open.value = false
    store.$patch({
      isMenuAccountOpen: false,
    })
  }
)

watch(
  () => open.value,
  () => {
    if (open.value) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }
)

// temporaire

const router = useRouter()

const gtmLogin = () => {
  gtm.trackEvent({
    event: 'login',
    action: 'click',
    value: 'Click login button in header',
  })

  router.push({ name: t('routes.login') })
}

const isOpenAccount = ref(false)
const buttons = ref()
const clickOutside = ref(false)
onClickOutside(buttons, () => {
  clickOutside.value = true
  isOpenAccount.value = false
  setTimeout(() => {
    clickOutside.value = false
  }, 100)
})

const clickAccount = () => {
  if (!clickOutside.value) isOpenAccount.value = true
  clickOutside.value = false
}
</script>

<template>
  <div class="border-grey-placeholder hidden border-b py-8">
    <div class="px-container">
      <div class="flex items-center justify-between">
        <div class="flex items-center"></div>
        <ak-navigation
          item-classes="hover:text-secondary-500 font-semibold text-primary-500"
          items-classes="gap-x-20"
          :menu="top"
        />
      </div>
    </div>
  </div>
  <header class="bg-primary z-50 py-18 lg:fixed lg:left-0 lg:right-0 lg:top-0">
    <UContainer>
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center lg:flex-1">
          <button
            v-if="menu && menu.length"
            class="flex lg:hidden"
            @click="closeMenu"
          >
            <UIcon
              :name="open ? 'i-fa6-solid-xmark' : 'i-fa6-solid-bars'"
              class="h-30 w-30 text-white"
            />
          </button>

          <NuxtLink to="/">
            <picture v-if="options && options.data.attributes.logo_header.data">
              <source
                media="(max-width: 767px)"
                :srcset="`https://wealtheurazeo.twic.pics/${options.data.attributes.logo_header.data.attributes.hash}${options.data.attributes.logo_header.data.attributes.ext}?twic=v1/cover-max=120`"
              />
              <img
                class="block h-auto w-120 max-w-full lg:w-160"
                :alt="
                  options.data.attributes.logo_header.data.attributes
                    .alternativeText
                "
                :src="`https://wealtheurazeo.twic.pics/${options.data.attributes.logo_header.data.attributes.hash}${options.data.attributes.logo_header.data.attributes.ext}?twic=v1/cover-max=160`"
              />
            </picture>
          </NuxtLink>
        </div>
        <div
          v-show="open"
          class="z-50 flex justify-center max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:right-0 max-lg:top-84 max-lg:bg-white max-lg:pt-100 lg:!flex lg:items-center"
        >
          <ak-navigation
            item-classes="relative font-semibold text-p2 text-primary lg:text-white lg:text-white after:content-[''] after:opacity-0 hover:after:opacity-100 after:absolute after:h-[3px] after:-bottom-6 after:w-28 after:left-1/2 after:-translate-x-1/2 after:rounded-full after:bg-secondary-500"
            items-classes="header-navigation flex max-lg:flex-col items-center justify-center gap-40"
            :menu="menu"
          />
        </div>
        <div
          v-if="status === 'authenticated'"
          class="flex items-center justify-end lg:flex-1"
        >
          <AuthHeaderAccount />
        </div>
        <div
          v-else-if="isLaptop || (!isLaptop && isOpenAccount)"
          ref="buttons"
          class="z-10 flex max-lg:absolute max-lg:right-20 max-lg:top-70 max-lg:flex-col lg:flex-1 lg:justify-end"
        >
          <UButton
            class="max-lg:bg-primary max-lg:w-200"
            color="transparent"
            variant="outline"
            to="/connexion"
            @click.prevent="gtmLogin"
          >
            {{ $t('layout.header.login') }}
          </UButton>
          <UButton
            v-if="status !== 'authenticated'"
            :ui="{
              color: {
                white: {
                  solid:
                    'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border-2 border-white bg-white text-primary hover:border-white',
                },
              },
            }"
            color="white"
            class="mt-10 max-lg:w-200 lg:ml-10 lg:mt-0"
            :to="{ name: t('routes.register') }"
          >
            {{ $t('layout.header.register') }}
          </UButton>
        </div>
        <div v-if="status !== 'authenticated' && isMobile">
          <button @click="clickAccount">
            <UIcon
              class="h-30 w-30 text-white"
              name="i-fa6-solid-circle-user"
            />
          </button>
        </div>
      </div>
    </UContainer>
  </header>
</template>

<style lang="scss" scoped>
:deep(.header-navigation) {
  .router-link-active {
    @apply after:opacity-100;
  }
}
</style>
